<template>
    <div class="jh-dialog-wrap">
  
      <div class="jh-dialog-header">
        <h1>이미지 설정</h1>
        <div class="is-right">
          <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
        </div>
      </div>
  
      <div class="jh-dialog-body">
        <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-success="uploadSuccess"
            class="form-drop"
        ></vue-dropzone>
        <div class="jh-info-txt is-fill is-mt-15">확장자는 <em>png, jpg, gif</em> , 용량은 </em>500 KB 이하</em>이며, 최대 <em>1개</em>까지 등록 가능합니다.</div>
        <div class="jh-info-txt is-fill">이미지 사이즈는 <em>720px * 720px</em>를 권장하며, <em>가로 500px 미만</em> 이미지는 사용할 수 없습니다.</div>
        <div class="jh-info-txt is-fill">가로:세로 비율이 <em>2:1 미만</em>이거나 <em>3:4 초과</em>하는 이미지는 사용할 수 없습니다.</div>
      </div>
  
      <div class="jh-dialog-footer" v-if="this.mixin_set_btn(this.$options.name, 'btnCancle')">
        <v-btn class="jh-btn is-md" @click="popupBtnClose">취소</v-btn>
        <v-btn class="jh-btn is-md is-del" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" @click="popupBtnDelete">삭제</v-btn>
        <v-btn class="jh-btn is-md is-main" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" @click="popupBtnUpload">저장</v-btn>
      </div>
      
    </div>
</template>

<script>

import {mixin} from "@/mixin/mixin";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "MENU_E020606P02", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
    headerTitle: {
      type: String,
      default: "문의유형 상세",
    },
    fileInfo: {
      type: Object,
      value: {},
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
    // Ckeditor,
  },
  mixins:[mixin],

  data() {
    return {
      gridListSelected: [],
      files: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: process.env.VUE_APP_API_URL + "api/file/chat/inqrytypeimage/uploads",
        //url: "https://dev.hkpalette.com/" + "api/file/chat/images/uploads",
        maxThumbnailFilesize: 1,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        thumbnailMethod: "crop",
        maxFilesize: 100,//this.fileInfo.maxSize,
        maxFiles: 1,//this.fileInfo.multi,
        addRemoveLinks: true,
        clickable: true,
        dictRemoveFile: '삭제',
        dictCancelUpload: "취소",
        //acceptedFiles: "[.png,.jpg,.gif]",
        acceptedFiles: ".png,.jpg,.gif",
        parallelUploads: 1,
        uploadMultiple: false,
        filesizeBase: 1000,

        paramName: "userfiles",
        /*
        paramName: function(e){
          return "userfiles"
        },
        */

        headers: {
          'Authorization': "",
        },
        params: {
          'aspNewCustKey': this.param.ASP_CUST_KEY,
          'aspCustKey': this.param.ASP_CUST_KEY,
          'procId': this.$store.getters['userStore/GE_USER_ROLE'].userId,
          'acceptedFiles': this.param.FILE_NEWTY,
          'uploadUri': "/api/file/chat/inqrytypeimage/uploads",
          'downloadUri': "/api/file/chat/inqrytypeimage/downloads",
          'fileUploadLib': "dropzone",
          'dir': "/EBEST/web/chat/inqrytypeimage",
          //'maxFilesize': 5,
          'tempDir': "/EBEST/web/chat/inqrytypeimage/temp",
          'targetType': "FILE",
          'fileGroupKey' : this.param.FILE_GROUP_KEY,
        },
      },
    };
  },

  methods: {
    popupBtnClose() {
      this.files = [];
      this.$emit('hide')
    },

    popupBtnDelete() {
      if(typeof this.param.INQ_ROW == 'number' && typeof this.param.INQ_COL == 'number'){
        this.$emit("submitDelete", this.param.INQ_ROW, this.param.INQ_COL);
      } else {
        this.$emit("submitDelete", null);
      }
      
      this.popupBtnClose();
    },

    popupBtnUpload(){
      let token = window.sessionStorage.getItem("token");
      this.dropzoneOptions.headers.Authorization = "bearer " + token;

      let get_files = this.$refs.myVueDropzone.getQueuedFiles();
      console.log("get_files :: " + JSON.stringify(get_files));

      console.log("get_files.length :: " + get_files.length);

      for (let i = 0;i<get_files.length;i++){
        this.files[i] = get_files[i].upload.filename;
      }

      console.log("fileType :: " + typeof(this.files));
      console.log("file :: " + this.files);

      if(get_files.length == 0){
        this.popupBtnClose();
      } else {
        this.$refs.myVueDropzone.processQueue();
      }
    },

    uploadSuccess(file, response){
      //이전화면 파라미터 전달추가
      if(typeof this.param.INQ_ROW != 'undefined'){
        console.log(`uploadSuccess INQ_ROW : ${this.param.INQ_ROW}`)
        response.data[0].INQ_ROW = this.param.INQ_ROW
      }
      if(typeof this.param.INQ_COL != 'undefined'){
        console.log(`uploadSuccess INQ_COL : ${this.param.INQ_COL}`)
        response.data[0].INQ_COL = this.param.INQ_COL
      }

      console.log("================ file ================")
      console.log(`this.param : ${JSON.stringify(this.param)}`)
      console.log(file)
      console.log("================ response ================")
      console.log(response)

      this.$emit('submitUpload', response.data);
    },


  },

  computed: {

  },

  created() {},
  mounted() {},
  updated() {},
  destroyed() {},
};

</script>

<style></style>