<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>문의유형관리</h1>
      <div class="is-right">
      </div>
    </div>

    <div class="jh-card" style="height: 750px;">      
      <div class="jh-card-header has-tab">
        <v-tabs v-model="tabScenario" slider-size="4">
          <v-tab @click="">TTALK</v-tab>
        </v-tabs>
      </div>
      <div class="jh-card-body is-scean" style="height: 1220px;">
        <v-tabs-items v-model="tabScenario">
          <v-tab-item class="jh-cols">
            <!--트리-->        
            <div class="jh-tree is-col-fix" style="width: 300px;">
              <div class="jh-tree-header">
                <div class="is-left">
                  <v-btn class="jh-btn is-sm is-light" @click="unfoldTreeView">전체 펼치기</v-btn>
                  <v-btn class="jh-btn is-sm is-light" @click="foldTreeView">전체 접기</v-btn>
                </div>
                <div class="is-right">
                  <v-btn class="jh-btn has-icon-only" @click="clickBtnRefresh"><i class="jh-icon-rotate-right"></i></v-btn>
                </div>
              </div>
              <div class="jh-tree-body">
                <v-treeview
                  class="jh-tree-view"
                  :items="treeItems"
                  open-all
                  return-object
                  activatable
                  ref="treeViewE020606"
                  @update:active="clickTreeItem"
                >
                  <template v-slot:prepend="{ open, item }">
                    <v-icon v-if="item.children">
                      {{open ? 'jh-icon-tree-folder-open' : 'jh-icon-tree-folder'}}
                  </v-icon>
                  <v-icon v-else>
                      {{ 'jh-icon-tree-file' }}
                  </v-icon>
                  </template>
                </v-treeview>
              </div>
            </div>

            <div class="is-inquiry">
              <div class="jh-scean-card" v-if="detailsDivUse == true">                
                <div class="jh-scean-card-header">
                  <h1>{{ details.title }}</h1>
                  <div class="is-right">
                    <v-switch v-model="details.useYn" label="사용" @click="clickTglBtnUseYn"></v-switch>                    
                  </div>
                </div>
                <div class="jh-scean-card-body">
                  <div class="is-image" @click="clickImgAdd">
                    <i class="jh-icon-image-big" v-if="this.imgPath === ''"></i>
                    <img :src="this.imgPath">                    
                      <!-- <v-img
                        class="tt-list-img"
                        @click="clickImgAdd"
                        :style="{
                          background: 'url('+imgPath+')',
                          'background-size': 'cover',
                        }"
                      >
                      </v-img> -->
                  </div>

                  <div class="jh-ui-header">
                    <h3 class="is-required">메시지</h3>
                    <div class="is-right">
                      <span class="jh-txt-counter"><em>0</em> / 1000</span>
                    </div>
                  </div>
                  <textarea v-model="details.msg" class="jh-form is-bg-white" style="height:80px;cursor: pointer;" placeholder="상담을 시작하면 서비스를 선택해 주세요." readonly @click="clickTextArea"></textarea>
                  
                  <draggable
                    v-model="detailChidList"
                    class="btn-group"
                    ghost-class="ghost"
                    :move="checkMove"
                    @start="list1_dragging = true"
                    @end="list1_dragging = false"
                  >
                    <v-btn
                      v-for="(item, i) in detailChidList"
                      :key="item.CD"
                      :hidden="item.USE_YN=='D'"
                      class="jh-btn is-light"
                      @click="clickBtnChildren(item, i)"
                    >
                      <i class="jh-icon-handler"></i>
                      {{ item.INQRY_TYP_NM }}
                    </v-btn>
                    <v-btn
                      v-if="Number(detailDataList.INQRY_TYP_DIV_CD) <= 3"
                      class="jh-btn is-noborder"
                      @click="clickBtnChildrenAdd"
                    >
                      <i class="jh-icon-plus-sm-bold"></i>
                    </v-btn>
                  </draggable>
                </div>
                <div class="jh-scean-card-footer">
                  <v-btn class="jh-btn is-del" :disabled="checkDepth" @click="clickBtnDetailDelete" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')">삭제</v-btn>
                  <v-btn class="jh-btn is-main" @click="clickBtnDetailSave" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')">저장</v-btn>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <!-- 팝업 모달창 -->
    <v-dialog
      v-if="DialogE020606P01"
      max-width="350"
      persistent
      v-model="DialogE020606P01"
    >
      <dialog-E020606P01
        headerTitle="dialog"
        @hide="hideDialog('E020606P01')"
        @Qsave="hideDialog('E020606P01')"
        :param="E020606P01Param"
        @getPopupData="setPopupData"
      >
      </dialog-E020606P01>
      <template v-slot:body> </template>
    </v-dialog>
    <v-dialog
      v-if="DialogE020606P02"
      max-width="350"
      persistent
      v-model="DialogE020606P02"
    >
      <dialog-E020606P02
        headerTitle="dialog"
        @hide="hideDialog('E020606P02')"
        :param="E020606P02Param"
        @submitUpload="uploadImg"
        @submitDelete="deleteImg"
      >
      </dialog-E020606P02>
      <template v-slot:body> </template>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import api from "@/store/apiUtil";
import { mixin } from "@/mixin/mixin";
import UserStore from "@/store/modules/userStore";
import DialogE020606P01 from "@/views/page/E020606P01";
import DialogE020606P02 from "@/views/page/E020606P02";

export default {
  name: "MENU_E020606", //name은 'MENU_' + 파일명 조합
  components: {
    DialogE020606P01,
    DialogE020606P02,
    draggable,
  },
  mixins: [mixin],

  data() {
    return {
      checkDepth:false,
      tabScenario:"",
      ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      ASP_NEWCUST_KEY: "",
      FILE_GROUP_KEY: "",

      imgUrlKey: {
        FILE_PATH: "",
        SAVE_FILENAME: "",
        FILE_GROUP_KEY: "",
        FILE_KEY: "",
      },

      list1_enabled: true,
      list1_dragging: false,

      DialogE020606P01: false,
      DialogE020606P02: false,
      E020606P01Param: {},
      E020606P02Param: {},

      dropItems: [],
      dropItemsV: {},

      treeItems: [],
      treeItemsV: {},

      detailsDivUse: false,
      detailChidList: [],
      tempChildList: [],
      delflag: true,
      detailDataList: {},
      details: {
        title: "title",
        msg: "",
        useYn: 0,
        qType: "",
        qTypeNm: "",
        FILE_GROUP_KEY: "",
        FILE_KEY: "",
      },

      popupDropItems: [],
      //qTypeTit: this.details.title,

      imgPath: "",
      // imgDir: "url(/upload/",
      imgDir: "url(/upload/",
    };
  },
   methods: {
    //*********************************************
    //           회사구분, 트리메뉴 method
    //*********************************************
    //회사구분 셀렉트박스 아이템 호출
    async getSelBoxList() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["URL"] = "/api/code/common/code-book/sender/aspinqry";
      requestData.headers["SERVICE"] = "code.common.code-book.sender";
      requestData.headers["METHOD"] = "aspinqry";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["ASP_USER_ID"] = this.ASP_USER_ID;

      let response = await this.common_postCall(requestData);
      this.getSelBoxListThen(response);
    },

    //회사구분 셀렉트박스 아이템 호출 후처리
    getSelBoxListThen(response) {
      this.dropItems = response[this.ASP_USER_ID];
            this.clickSelBoxItem(this.dropItems[0]);

    },

    //트리 아이템 호출
    async clickSelBoxItem(item) {
      this.ASP_NEWCUST_KEY = item.ASP_CUST_KEY;
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage.chnnl-tr";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["URL"] =
        "/api/chat/setting/inqry-ty-manage/chnnl-tr/inqire";

      requestData.sendData["BIZ_SERVICES_CD"] = "TTALK"; // 카카오톡
      requestData.sendData["ASP_NEWCUST_KEY"] = item.ASP_CUST_KEY;

      let response = await this.common_postCall(requestData);
      this.clickSelBoxItemThen(response);
    },

    //트리 아이템 호출 후처리(트리메뉴 구성)
    clickSelBoxItemThen(response) {
      let data = response.DATA;
      let items = [];

      if (data.length != 0 && data.at(0).USE_YN == "Y") {
        for (var i = 0; i < data.length; i++) {
          let element = {
            id: data[i].NODE_ID,
            name: data[i].NODE_TITLE,
            cNode: data[i].C_NODE_NO,
            pNode: data[i].P_NODE_NO,
            //dNode : data[i].D_NODE_NO,
            nodeInfo: data[i].NODE_ETC,
            nodeType: data[i].NODE_TYPE,
            nodeLvl: data[i].NODE_LVL,
            inqryUseCh: data[i].NODE_INQRY_USE_CHANNEL,
            nodeKey: data[i].NODE_KEY,
          };

      console.log("element.nodeLvl      ", element.nodeLvl);
      console.log("element      ", element);
          if (data[i].USE_YN == "N") element["locked"] = true;



          if (data[i].NODE_TYPE == "D") element.children = [];

          // if (element.nodeLvl == 1) {
          //   for (var j = 0; j < items.length; j++) {
          //     if (element.pNode == items[j].cNode) {
          //       items[j].children.push(element);
          //     }
          //   }
          // }
          if (element.nodeLvl == 1) {
            for (var j = 0; j < items.length; j++) {
              if (element.pNode == "*") {
                items[j].children.push(element);
              }
            }
          } else if (element.nodeLvl == 2) {
            for (var k = 0; k < items.length; k++) {
              if(items[k].children != undefined){
                for (var l = 0; l < items[k].children.length; l++) {
                  if (element.pNode == items[k].children[l].cNode) {
                    items[k].children[l].children.push(element);
                  }
                }
              }
            }
          } else if (element.nodeLvl == 3) {
            for (var k = 0; k < items.length; k++) {
              if(items[k].children != undefined){
                for (var l = 0; l < items[k].children.length; l++) {
                  if(items[k].children[l].children != undefined){
                    for (var m = 0; m < items[k].children[l].children.length; m++) {
                      if (element.pNode == items[k].children[l].children[m].cNode) {
                        items[k].children[l].children[m].children.push(element);
                      }
                    }
                  }
                }
              }
            }
          } else if (element.nodeLvl == 4) {
            for (var k = 0; k < items.length; k++) {
              if(items[k].children != undefined){
                for (var l = 0; l < items[k].children.length; l++) {
                  if(items[k].children[l].children != undefined){
                    for (var m = 0; m < items[k].children[l].children.length; m++) {
                      if (items[k].children[l].children[m].children != undefined) {
                        for (var n = 0; n < items[k].children[l].children[m].children.length; n++) {
                          if (element.pNode == items[k].children[l].children[m].children[n].cNode) {
                            items[k].children[l].children[m].children[n].children.push(element);
                          }
                        }
                        // items[k].children[l].children[m].children.push(element);
                      }
                    }
                  }
                }
              }
            }
          } else {
            items.push(element);
          }
        }
      } else if (data.length != 0 && data.at(0).USE_YN == "N") {
        for (var i = 0; i < data.length; i++) {
          let element = {
            id: data[i].NODE_ID,
            name: data[i].NODE_TITLE,
            cNode: data[i].C_NODE_NO,
            pNode: data[i].P_NODE_NO,
            //dNode: data[i].D_NODE_NO,
            nodeInfo: data[i].NODE_ETC,
            nodeType: data[i].NODE_TYPE,
            nodeLvl: data[i].NODE_LVL,
            inqryUseCh: data[i].NODE_INQRY_USE_CHANNEL,
            nodeKey: data[i].NODE_KEY,
            locked: true,
          };
          if (data[i].NODE_TYPE == "D") {
            element.children = [];
          }
          items.push(element);
        }
      } else {
        this.common_alert("조회된 데이터가 없습니다", "error");
      }

      this.treeItems = items;
      console.log("treeItem  streeItemstreeItemstreeItems       ", this.treeItems);
      console.log("treeItem  streeItemstreeItemstreeItems       ", this.treeItems);

      this.unfoldTreeView();
      this.getPopupSelBoxItems();
    },

    //트리 아이템 클릭 이벤트
    async clickTreeItem(item) {
      console.log("item111111", item);
      if(item[0].nodeKey == 2){
        this.checkDepth = true
      }else{
        this.checkDepth = false
      }
      if (item.length == 0) {
        return;
      }
      const SYSTEM_MSG_ID = "MSG34899";
      (this.imgPath = ""), (this.treeItemsV = {});
      this.treeItemsV = item;

      let requestData = { headers: {}, sendData: {} };
      let spst_inqry_typ_cd;

      if( item.at(0).inqryUseCh == item.at(0).nodeKey){
        requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/chnnl/detail";
        requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage.chnnl";

        spst_inqry_typ_cd = "*";

      }else{
        requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/detail";
        requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage";

        spst_inqry_typ_cd = item.at(0).nodeKey;
      }    

      // requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/chnnl/detail";
      // requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/detail";
      // requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage";
      requestData.headers["METHOD"] = "detail";
      requestData.headers["ASYNC"] = "true";
      requestData.headers["CALL_BACK"] =
        "ChatSettingInquiryTypeManage.callback_selectRtnNodeDetail";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      
      
      // if( item.at(0).inqryUseCh == item.at(0).nodeKey){
      //   spst_inqry_typ_cd = "*";

      // }else{
      //   spst_inqry_typ_cd = item.at(0).nodeKey;
      // }      
      requestData.sendData["INQRY_TYP_CD"] = spst_inqry_typ_cd;
      requestData.sendData["INQRY_USE_CHANNEL"] = item.at(0).inqryUseCh;
      requestData.sendData["SYSTEM_MSG_ID"] = SYSTEM_MSG_ID;
      console.log("requestData   ", requestData);
      console.log("requestData   ", requestData);
      console.log("requestData   ", requestData);

      let response = await this.common_postCall(requestData);
      this.delflag = true;

      this.clickTreeItemThen(response);
    },

    clickTreeItemThen(response) {
      let data = response;
      if (data.INQRY_DETAIL.length == 0) {
        this.common_alert("Detail Data Error", "error");
        return;
      }
      console.log("data", data);

      this.detailsDivUse = true;
      this.details.title = data.INQRY_DETAIL.at(0).CD_NM;
      this.details.msg =
        data.INQRY_DETAIL.at(0).INQRY_DESC != ""
          ? data.INQRY_DETAIL.at(0).INQRY_DESC
          : "";
      this.details.useYn = data.INQRY_DETAIL.at(0).USE_YN == "Y" ? true : false;
      this.details.qType = data.INQRY_DETAIL.at(0).INQRY_TYPE;
      this.details.qTypeNm = data.INQRY_DETAIL.at(0).INQRY_TYP_NM;

      this.detailDataList = data.INQRY_DETAIL.at(0);

            console.log("this.detailDataList  ", this.detailDataList);

      this.detailChidList = data.INQRY_CHILDREN;


      this.details.FILE_GROUP_KEY = "";
      this.details.FILE_KEY = "";

      if (data.INQRY_DETAIL.at(0).FILE_PATH != "") {

                    console.log("data.INQRY_DETAIL.at(0).FILE_PATH !=    ", data.INQRY_DETAIL.at(0).FILE_PATH != "");

        this.details.FILE_GROUP_KEY = data.INQRY_DETAIL.at(0).FILE_GROUP_KEY;
        this.details.FILE_KEY = data.INQRY_DETAIL.at(0).FILE_KEY;
        // this.setImageUrl(data.INQRY_DETAIL.at(0));
        this.setImageUrl(data.INQRY_DETAIL);
      }


                  console.log("this.details  ", this.details);

    },

    //새로고침 버튼 이벤트
    clickBtnRefresh() {

                  console.log("this.detailDataList  ", this.dropItems[0]);

      this.clickSelBoxItem(this.dropItems[0]);
      this.foldTreeView();
      this.detailsDivUse = false;
    },

    unfoldTreeView() {
      this.$refs.treeViewE020606.updateAll(true);
    },
    foldTreeView() {
      this.$refs.treeViewE020606.updateAll(false);
    },

    //*********************************************
    //             디테일 메뉴 메소드
    //*********************************************
    // 디테일 팝업 셀렉트박스 아이템 호출
    async getPopupSelBoxItems() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["SERVICE"] = "code.common.code-book";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["URL"] = "/api/code/common/code-book/inqry";

      requestData.sendData["GROUP_CD"] = "TALK029";

      let response = await this.common_postCall(requestData);
      this.getPopupSelBoxItemsThen(response);
    },

    // 디테일 팝업 셀렉트박스 아이템 호출 후처리
    getPopupSelBoxItemsThen(response) {
      this.popupDropItems = response.TALK029;
    },

    // 사용여부 useYn 스위치
    clickTglBtnUseYn() {
      console.log(this.details.useYn);
    },

    // 디테일 첨부이미지 팝업
    clickImgAdd() {
      console.log("clickImgAdd Click~!!!");
      console.log("clickImgAdd Click~!!!", this.dropItems[0].ASP_CUST_KEY);
      console.log("clickImgAdd Click~!!!");
      this.E020606P02Param = {
        fileInfo: {},
        FILE_NUM: "1",
        FILE_SIZE: "5",
        FILE_NEWTY: [".jpg", ".png", ".gif"],

        FILE_TYPE: [
          { text: ".jpg", selected: true },
          { text: ".png", selected: true },
          { text: ".gif", selected: true },
          { text: ".pdf", selected: true, isLast: true },
        ],

        // ASP_CUST_KEY: this.dropItemsV.CD,
        // ASP_NEWCUST_KEY: this.dropItemsV.CD,
        ASP_CUST_KEY: this.dropItems[0].ASP_CUST_KEY,
        ASP_NEWCUST_KEY: this.dropItems[0].ASP_CUST_KEY,
        UPLOAD_URI: "/api/file/chat/images/uploads",
        DOWNLOAD_URI: "/api/file/chat/images/downloads",
        DIR: "/EBEST/web/chat/inqrytypeimage",
        TEMP_DIR:"/EBEST/web/chat/inqrytypeimage/temp",
        TARGET_TY: "FILE",
        FILE_GROUP_KEY: this.imgUrlKey.FILE_GROUP_KEY,
      };
      this.showDialog("E020606P02");
    },

    // 디테일 textArea 팝업
    clickTextArea() {
      this.E020606P01Param = {
        headerTitle: "문의유형 상세",
        childFlag: "area",
        userId: this.$store.getters["userStore/GE_USER_ID"],
        selBoxItems: this.popupDropItems,
        popupDetails: {
          INQRY_TYPE: this.details.qType,
          msg: this.details.msg,
        },
      };
      this.showDialog("E020606P01");
    },

    // 디테일 하위메뉴 팝업
    clickBtnChildren(item, i) {
      this.E020606P01Param = {
        checkDepth: this.checkDepth,
        childFlag: "child",
        childIndex: i,
        userId: this.$store.getters["userStore/GE_USER_ID"],
        selBoxItems: this.popupDropItems,
        popupDetails: {
          INQRY_TYPE: item.INQRY_TYPE,
          INQRY_TYP_NM: item.INQRY_TYP_NM,
        },
      };
      this.showDialog("E020606P01");
    },

    // 디테일 하위메뉴 add 팝업
    clickBtnChildrenAdd() {
      this.E020606P01Param = {
        childFlag: "add",
        userId: this.$store.getters["userStore/GE_USER_ID"],
        selBoxItems: this.popupDropItems,
        popupDetails: {
          INQRY_TYPE: this.details.INQRY_TYPE,
        },
      };
      this.showDialog("E020606P01");
    },


    //팝업 후처리
    setPopupData(params) {
      switch (params.flag) {
        case "mod":
          if(params.cFlag === "area") {
            this.details.msg = params.msg;
            this.details.qType = params.inqryType
          }
          if(params.cFlag === "child") {
            this.detailChidList[params.childIndex].INQRY_TYPE = params.inqryType;
            this.detailChidList[params.childIndex].INQRY_TYP_NM = params.title;
          }
          break;

        case "del":
          //this.detailChidList.splice(params.sendData, 1);
          this.detailChidList[params.sendData].USE_YN = "D";
          console.log("safsafsa   ", this.detailChidList[params.sendData]);
          console.log("this.detailChidList   ", this.detailChidList);

          if(this.delflag == true){
            for(let i = 0; i < this.detailChidList.length; i++){
              this.tempChildList[i] = this.detailChidList[i];
            }          
          }else{
            this.tempChildList[params.sendData] = this.detailChidList[params.sendData];
          }
          // for(let i = 0; i < this.detailChidList.length; i++){
          //   this.tempChildList[i] = this.detailChidList[i];
          // }
          console.log("this.detailChidList detailChidList   ", this.detailChidList);
          console.log("this.tempChildList   ", this.tempChildList);

          // this.tempChildList.push(this.detailChidList[params.sendData]);
          // for(let i = 0; i < this.detailChidList.length; i++){

          //             console.log("this.detailChidList.length   ", this.detailChidList[i].CD);
          //             console.log("this.detailChidList[params.sendData].CD   ", this.detailChidList[params.sendData].CD);

            // if(this.detailChidList[i].CD === this.detailChidList[params.sendData].CD){
              this.detailChidList.splice(params.sendData, 1);
          //     i--;
          //   }
          // }
          this.delflag = false;
          break;

        case "add":
          let tempObj = {
            INQRY_TYP_CD: "",
            INQRY_TYP_NM: params.sendData.INQRY_TYP_NM,
            INQRY_TYPE: params.sendData.INQRY_TYPE,
            INQRY_TYP_DIV_CD: Number(this.detailDataList.INQRY_TYP_DIV_CD) + 1,
            USE_YN: "Y",
            CD: Date.now(),
          };
          this.detailChidList.push(tempObj);
          break;

        default:
          break;
      }
    },

    //이미지 파일 팝업 업로드 후
    async uploadImg(item) {
      this.details.FILE_GROUP_KEY = item[0].fileGroupKey;
      this.details.FILE_KEY = item[0].fileKey;

      let data = [{
        FILE_KEY: item[0].fileKey,
        FILE_GROUP_KEY: item[0].fileGroupKey,
      }];
      this.setImageUrl(data);
      this.hideDialog("E020606P02");
    },

    // 첨부된 이미지 삭제(삭제 쿼리는 돌지 않음)
    deleteImg() {
      this.imgPath = "";

      this.details.FILE_GROUP_KEY = "";
      this.details.FILE_KEY = "";
    },

    // 첨부파일 리스트 불러오기
    async getAtcFileList(item) {
      let requestData = { headers: {}, sendData: {} };
      // header 세팅
      requestData.headers["URL"]     = "api/km/content/regist/file/inqire";
      requestData.headers["SERVICE"] = "km.content.regist.file";
      requestData.headers["METHOD"]  = "inqire";
      requestData.headers["TYPE"]    = "BIZ_SERVICE";
      requestData.headers["ASYNC"]   = false;

      // sendData 세팅
      requestData.sendData["FILE_GROUP_KEY"]  = item.fileGroupKey;
      requestData.sendData["ASP_NEWCUST_KEY"] = item.aspNewCustKey;

      // 결과 리턴
      let response = await this.common_postCall(requestData);
                  console.log("response");

      console.log( response);
      console.log("response");
      console.log("response");
      console.log("response");
      this.setImageUrl(response);
    },

    async setImageUrl(data) {
      // this.imgUrlKey.FILE_PATH = data[0].FILE_PATH;
      // this.imgUrlKey.SAVE_FILENAME = data[0].SAVE_FILENAME;
      // this.imgPath =
      //   this.imgDir + data.FILE_PATH + "/" + data.SAVE_FILENAME + ")";
      // console.log(this.imgPath);
      data = await this.mixin_imageView('chat', 'inqrytypeimage', data);
            console.log("data");

      console.log( "clickImgAddclickImgAddclickImgAdd", data);
      console.log("data");
      console.log("data");
      console.log("data");
      this.imgPath = data[0].src;

      console.log(this.imgPath);
    },

    // 디테일 삭제 confirm dialog
    clickBtnDetailDelete() {
      this.common_confirm(
        "삭제 하시겠습니까?",
        this.delConfirm,
        null,
        null,
        null,
        "error"
      );
    },

    async delConfirm() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/delete";
      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      requestData.sendData["INQRY_TYP_CD"] = this.detailDataList.INQRY_TYP_CD;
      requestData.sendData["INQRY_TYP_DIV_CD"] =
        this.detailDataList.INQRY_TYP_DIV_CD;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;

      let response = await this.common_postCall(requestData);
      this.clickBtnDetailDeleteThen(response);
      this.delflag = true;
    },

    clickBtnDetailDeleteThen(response) {
      if (!response.HEADER.ERROR_FLAG) {
        this.common_alert("정상적으로 삭제되었습니다.", "done");
        this.clickSelBoxItem(this.dropItems[0]);
        this.detailsDivUse = false;
      } else {
        this.common_alert(response.HEADER.ERROR_MSG, "error");
      }
    },

    // 디테일 저장 버튼
    async clickBtnDetailSave() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["SERVICE"] = "chat.setting.inqry-ty-manage";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["ASYNC"] = "false";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/regist";

      requestData.sendData["REG_ID"] = this.ASP_USER_ID;
      requestData.sendData["REG_DEPT_CD"] = this.detailDataList.REG_DEPT_CD;
      requestData.sendData["TRANS_STATUS"] = "U";
      requestData.sendData["INQRY_USE_CHANNEL"] =
        this.detailDataList.INQRY_USE_CHANNEL;
      requestData.sendData["SPST_INQRY_TYP_CD"] =
        this.detailDataList.SPST_INQRY_TYP_CD;
      requestData.sendData["INQRY_TYP_CD"] = this.detailDataList.INQRY_TYP_CD;
      requestData.sendData["INQRY_TYP_NM"] = this.details.qTypeNm;
      requestData.sendData["SORT_ORD"] = this.detailDataList.SORT_ORD;
      requestData.sendData["INQRY_TYP_DIV_CD"] =
        this.detailDataList.INQRY_TYP_DIV_CD;
      requestData.sendData["USE_YN"] = this.details.useYn == true ? "Y" : "N";
      requestData.sendData["INQRY_TYPE"] = this.details.qType;
      requestData.sendData["INQRY_DESC"] = this.details.msg;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["FILE_GROUP_KEY"] = this.details.FILE_GROUP_KEY;
      requestData.sendData["FILE_KEY"] = this.details.FILE_KEY; // 4dep 은 여기까지만 data 보냄

      if (this.detailDataList.INQRY_TYP_DIV_CD != "4") {
        requestData.headers["DATA_OBJECT"] = "INQRY_CHILDREN";

        // children 있을 경우 param 담기
        let tempList = [];
        let spst_inqry_typ_cd;

          if(this.tempChildList.length != 0){
            for (let i in this.tempChildList) {

              if(this.detailDataList.INQRY_USE_CHANNEL == this.detailDataList.INQRY_TYP_CD){
                spst_inqry_typ_cd = "*";
              }else{
                spst_inqry_typ_cd = this.detailDataList.INQRY_TYP_CD;
              }
              let tempObj = {
                SPST_INQRY_TYP_CD: spst_inqry_typ_cd,
                SORT_ORD         : this.tempChildList[i].USE_YN == "D" ? "999" : i.toString(),
                INQRY_TYP_CD     : this.tempChildList[i].INQRY_TYP_CD,
                INQRY_TYP_NM     : this.tempChildList[i].INQRY_TYP_NM,
                INQRY_TYPE       : this.tempChildList[i].INQRY_TYPE,
                INQRY_TYP_DIV_CD : this.tempChildList[i].INQRY_TYP_DIV_CD,
                USE_YN           : this.tempChildList[i].USE_YN,
                DATA_FLAG        : "false",
              };
              if (i == 0) tempObj["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
              tempList.push(tempObj);
            }
          }else{
            for (let i in this.detailChidList) {

              if(this.detailDataList.INQRY_USE_CHANNEL == this.detailDataList.INQRY_TYP_CD){
                spst_inqry_typ_cd = "*";
              }else{
                spst_inqry_typ_cd = this.detailDataList.INQRY_TYP_CD;
              }
              let tempObj = {
                SPST_INQRY_TYP_CD: spst_inqry_typ_cd,
                SORT_ORD         : this.detailChidList[i].USE_YN == "D" ? "999" : i.toString(),
                INQRY_TYP_CD     : this.detailChidList[i].INQRY_TYP_CD,
                INQRY_TYP_NM     : this.detailChidList[i].INQRY_TYP_NM,
                INQRY_TYPE       : this.detailChidList[i].INQRY_TYPE,
                INQRY_TYP_DIV_CD : this.detailChidList[i].INQRY_TYP_DIV_CD,
                USE_YN           : this.detailChidList[i].USE_YN,
                DATA_FLAG        : "false",
              };
              if (i == 0) tempObj["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
              tempList.push(tempObj);
            }
          }


        // for (let i in this.detailChidList) {

        //   if(this.detailDataList.INQRY_USE_CHANNEL == this.detailDataList.INQRY_TYP_CD){
        //     spst_inqry_typ_cd = "*";
        //   }else{
        //     spst_inqry_typ_cd = this.detailDataList.INQRY_TYP_CD;
        //   }
        //   let tempObj = {
        //     SPST_INQRY_TYP_CD: spst_inqry_typ_cd,
        //     SORT_ORD         : this.detailChidList[i].USE_YN == "D" ? "999" : i.toString(),
        //     INQRY_TYP_CD     : this.detailChidList[i].INQRY_TYP_CD,
        //     INQRY_TYP_NM     : this.detailChidList[i].INQRY_TYP_NM,
        //     INQRY_TYPE       : this.detailChidList[i].INQRY_TYPE,
        //     INQRY_TYP_DIV_CD : this.detailChidList[i].INQRY_TYP_DIV_CD,
        //     USE_YN           : this.detailChidList[i].USE_YN,
        //     DATA_FLAG        : "false",
        //   };
        //   if (i == 0) tempObj["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
        //   tempList.push(tempObj);
        // }
        requestData.sendData["INQRY_CHILDREN"] = tempList;
      }

      this.tempChildList = [];

      console.log("save detail ", this.details)
      this.delflag = true;

      let response = await this.common_postCall(requestData);
      this.clickBtnDetailSaveThen(response);
    },

    clickBtnDetailSaveThen(response) {
      if (response.HEADER.ERROR_FLAG == true) {
        this.common_alert("시스템에 문제가 생겼습니다.<br>관리자에게 문의하세요.", "error");
      } else {
        this.common_alert("정상적으로 처리되었습니다.", "done");
        // this.clickSelBoxItem(this.dropItemsV);
        this.getSelBoxList();
        this.clickTreeItem(this.treeItems);
      }
    },

    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    showDialog(menu) {
      console.log("showDialog");
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      console.log("hideDialog");
      this[`Dialog${menu}`] = false;
    },
  },
  computed: {},

  created() {},

  mounted() {
    console.log("~init~ E020606(문의유형관리)");
    this.getSelBoxList();
    this.getPopupSelBoxItems();
  },
};
</script>

<!--<template v-slot:label>-->
<!--  회사구분-->
<!--  <v-icon color="#EF9191">mdi-circle-small</v-icon>-->
<!--</template>-->

<style scoped>
/* .tt-card-body {
  background-color: #c8cdde;
  padding: 20px 20px 10px;
  width: 340px;
  box-sizing: border-box;
}
.tt-mssge-body {
  max-width: 320px;
  border-radius: 0 20px 20px;
  overflow: hidden;
  background-color: #fff;
}
.tt-list-img {
  width: 100%;
  min-height: 160px;
  background: url(../../assets/img-mssg-img.png) no-repeat 50% 50% #ebeef2;
  cursor: pointer;
}
.tt-talk-box {
  padding: 10px;
}
.tt-talk-box .tit-h3 {
  margin-bottom: 5px;
}
.tt-talk-box textarea {
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  font-size: 13px;
  line-height: 1.2;
  resize: none;
}
.tt-list-btn ul {
  padding: 0;
  margin: 0;
}
.tt-list-btn ul li {
  margin-bottom: 2px;
}
.tt-list-btn ul li .tt-btn {
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  border: 1px solid #b6b7bf;
  background-color: #ebecf2;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
}
.tt-list-btn ul li .tt-btn .tt-handler {
  position: absolute;
  top: 0;
  left: 5px;
  width: 20px;
  height: 100%;
  background: url(../../assets/icon-handler-vrt.png) no-repeat center center;
}
.ghost {
  opacity: 0.5;
  box-shadow: 0 0 0 3px orange inset;
  background: red !important;
} */
</style>
