<template>
    <div class="jh-dialog-wrap">
  
      <div class="jh-dialog-header">
        <h1>하위문의유형</h1>
        <div class="is-right">
          <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
        </div>
      </div>
  
      <div class="jh-dialog-body">
        <dl class="jh-list-form">
          <dt v-if="param.childFlag == 'child' || param.childFlag == 'add'"><label class="is-required">하위 문의유형명</label></dt>
          <dd v-if="param.childFlag == 'child' || param.childFlag == 'add'">
            <v-text-field class="jh-form is-lg" v-model="txtFieldV" ></v-text-field>
          </dd>
          <dt><label class="is-required">문의유형 타입</label></dt>
          <dd>  
            <v-select
                class="jh-form is-lg"
                :items="dropTypeItems"
                item-text="CD_NM"
                item-value="CD"
                return-object               
                placeholder="선택하세요"
                v-model="dropItemsV"
            >
            </v-select>
          </dd>
          <dt v-if="dropItemsV.CD == 'URL'"><label class="is-required">URL</label></dt>
          <dd v-if="dropItemsV.CD == 'URL'">
            <v-text-field class="jh-form is-lg" v-model="txtUrlV" ></v-text-field>
          </dd>
          <dt v-if="param.childFlag == 'area'">
            <label>문의유형 메시지</label>
            <div class="is-right"><span class="jh-txt-counter"><em>{{txtAreaV? txtAreaV.length : 0}}</em> / 1000</span></div>
          </dt>
          <dd v-if="param.childFlag == 'area'">
            <v-textarea
              class="jh-form"
              v-if="param.childFlag == 'area'"
              placeholder="문의 유형 메시지"
              maxlength="1000"
              cols="30"
              rows="10"           
              v-model="txtAreaV"
            >
            </v-textarea>
          </dd>
        </dl>
        <template v-if="typeof param.childRowIndex != 'undefined'">
          <div class="jh-info-txt is-fill"><em>메시지타입</em></div>
          <p class="jh-desc">메시지 전송 후 상담이 종료됩니다.</p>
          <div class="jh-info-txt is-fill"><em>URL타입</em></div>
          <p class="jh-desc">URL이 연동되는 버튼이 전달됩니다.</p>
        </template>
        <template v-else>
          <div class="jh-info-txt is-fill is-mt-10"><em>상담사연결형타입</em> (기본형)</div>
          <p class="jh-desc">하위 문의유형이 있는 경우 하위 문의유형을 출력하고, 없는 경우 상담사에게 연결합니다</p>
          <!-- <div class="jh-info-txt is-fill"><em>메시지타입</em></div>
          <p class="jh-desc">메시지 전송 후 상담이 종료됩니다.</p>
          <div class="jh-info-txt is-fill"><em>챗봇연결형타입</em> (카카오톡 전용)</div>
          <p class="jh-desc">카카오톡 챗봇이 연동된 경우에만 사용 가능하며, 설정시 챗봇 블록으로 이동합니다.</p> -->
        </template>
        
      </div>
  
      <div class="jh-dialog-footer">
        <v-btn class="jh-btn is-md" @click="popupBtnClose" v-if="this.mixin_set_btn(this.$options.name, 'btnCancle')">취소</v-btn>
        <v-btn class="jh-btn is-md is-del" :disabled="checkDepth" @click="popupBtnDelete" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete') && param.childFlag == 'child'">삭제</v-btn>
        <v-btn class="jh-btn is-md is-main" @click="popupBtnModify" v-if="this.mixin_set_btn(this.$options.name, 'btnMody') && param.childFlag != 'add'">수정</v-btn>
        <v-btn class="jh-btn is-md is-main" @click="popupBtnAdd" v-if="this.mixin_set_btn(this.$options.name, 'btnAdd') && param.childFlag == 'add'">저장</v-btn>
      </div>
    </div>
    <!-- <div class="modal-box">
      <div>
        <div class="">
          <div class="">
            <div>
              <div class="d-flex" v-if="param.childFlag == 'child' || param.childFlag == 'add'">
                <h4 class="mt-3">하위 문의유형명</h4>
                <v-icon color="#EF9191" left>mdi-circle-small</v-icon>
              </div>
              <v-text-field
                  v-if="param.childFlag == 'child' || param.childFlag == 'add'"
                  class="form-inp full"
                  outlined
                  hide-details
                  v-model="txtFieldV"
              ></v-text-field>

              <div class="d-flex">
                <h4 class="mt-3">문의유형 타입</h4>
                <v-icon color="#EF9191" left>mdi-circle-small</v-icon>
              </div>
              <v-select
                  class="form-inp full"
                  :items="dropTypeItems"
                  item-text="CD_NM"
                  item-value="CD"
                  return-object
                  outlined
                  hide-details
                  placeholder="선택하세요"
                  v-model="dropItemsV"
              >
              </v-select>
              <div class="d-flex" v-if="param.childFlag == 'area'">
                <h4 class="mt-3" >문의유형 메시지</h4>
                <v-icon color="#EF9191" left>mdi-circle-small</v-icon>
              </div>
              <v-textarea
                  v-if="param.childFlag == 'area'"
                  class="mt-2"
                  placeholder="문의 유형 메시지"
                  outlined
                  cols="30"
                  rows="10"
                  no-resize
                  v-model="txtAreaV"
                  hide-details
              >
              </v-textarea>
            </div>
            <div class="mt-3">
              <div>
                *<strong class="clr-error">상담사연결형타입</strong>(기본형)
                <p class="text-subtitle-2">하위 문의유형이 있는 경우 하위 문의유형을 출력하고, 없는 경우 상담사에게 연결합니다</p>
              </div>
              <div>
                *<strong class="clr-error">메시지타입</strong>
                <p class="text-subtitle-2">메시지 전송 후 상담이 종료됩니다.</p>
              </div>
              <div>
                *<strong class="clr-error">챗봇연결형타입(카카오톡 전용)</strong>
                <p class="text-subtitle-2">카카오톡 챗봇이 연동된 경우에만 사용 가능하며, 설정시 챗봇 블록으로 이동합니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mt-3">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCancle')" outlined class="btn-etc"    @click="popupBtnClose">취소</v-btn>
          <v-btn outlined class="btn-point ml-2" @click="popupBtnDelete" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete') && param.childFlag == 'child'" >삭제</v-btn>
          <v-btn outlined class="btn-default ml-2  " @click="popupBtnModify" v-if="this.mixin_set_btn(this.$options.name, 'btnMody') && param.childFlag != 'add'">수정</v-btn>
          <v-btn outlined class="btn-default ml-2  " @click="popupBtnAdd" v-if="this.mixin_set_btn(this.$options.name, 'btnAdd') && param.childFlag == 'add'">추가</v-btn>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>

import {mixin} from "@/mixin/mixin";

export default {
  name: "MENU_E020606P01", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
    headerTitle: {
      type: String,
      default: "문의유형 상세",
    },
  },
  components: {
  //  Ckeditor,
  },
  mixins:[mixin],

  data: () => ({
    txtFieldV    : '',
    dropTypeItems: [],
    dropItemsV   : '',
    txtAreaV     : '',
    txtUrlV      : '',

    footerHideTitle: "닫기",

    gridListSelected: [],
    checkDepth:false,

  }),
  methods: {
    popupBtnClose() {
      this.$emit('hide')
    },

    popupBtnDelete() {
      let params = {
        flag : "del",
        sendData : this.param.childIndex,
        childRowIndex: this.param.childRowIndex,
        childColIndex: this.param.childColIndex,
      }
      this.$emit("getPopupData", params);
      this.popupBtnClose();
    },

    popupBtnModify() {
      let params = {};
      if(this.param.childFlag == 'area') {
        if(this.mixin_isEmpty(this.dropItemsV)/* || this.mixin_isEmpty(this.txtAreaV)*/) {
          this.common_alert("문의유형타입은 입력입니다.", "error")
          return;
        }
        params = {
          flag : "mod",
          cFlag : "area",
          msg : this.txtAreaV,
          inqryType : this.dropItemsV.CD,
          childRowIndex: this.param.childRowIndex,
          childColIndex: this.param.childColIndex,
        }
      }

      if(this.param.childFlag == 'child' && this.dropItemsV.CD == 'URL') { //child는 같고 URL일때 별도처리
        if(this.mixin_isEmpty(this.dropItemsV) || this.mixin_isEmpty(this.txtFieldV) || this.mixin_isEmpty(this.txtUrlV)) {
          this.common_alert("문의유형타입과 메시지, URL은 필수 입력입니다.", "error")
          return;
        }
        params = {
          flag : "mod",
          cFlag: "url",
          childIndex: this.param.childIndex,
          childRowIndex: this.param.childRowIndex,
          childColIndex: this.param.childColIndex,
          title     : this.txtFieldV,
          url : this.txtUrlV,
          inqryType : this.dropItemsV.CD,
        }
      } else if(this.param.childFlag == 'child') {
        if(this.mixin_isEmpty(this.dropItemsV) || this.mixin_isEmpty(this.txtFieldV)) {
          this.common_alert("문의유형명과 타입은 필수 입력입니다.", "error")
          return;
        }
        params = {
          flag : "mod",
          cFlag: "child",
          childIndex: this.param.childIndex,
          childRowIndex: this.param.childRowIndex,
          childColIndex: this.param.childColIndex,
          title     : this.txtFieldV,
          inqryType : this.dropItemsV.CD,
        }
      }

      this.$emit("getPopupData", params);
      this.popupBtnClose();
    },

    popupBtnAdd() {
      if(this.mixin_isEmpty(this.dropItemsV) || this.mixin_isEmpty(this.txtFieldV)) {
        this.common_alert("문의유형명과 타입은 필수 입력입니다.", "error")
        return;
      }
      let params = {
        flag : "add",
        childRowIndex: this.param.childRowIndex,
        childColIndex: this.param.childColIndex,
        childIndex: this.param.childIndex,
        sendData : {
          INQRY_TYP_NM: this.txtFieldV,
          INQRY_TYPE  : this.dropItemsV.CD,
          INQRY_URL : this.txtUrlV,
        }
      }      
      this.$emit("getPopupData", params);
      this.popupBtnClose();
    },
  },

  created() {

  },

  mounted() {
    this.dropTypeItems = this.param.selBoxItems;
    //this.dropItemsV = this.dropTypeItems[0];
    console.log(`************** this.param.childRowIndex : ${typeof this.param.childRowIndex}`)
    console.log("this.param  ", this.param)
    this.checkDepth = this.param.checkDepth;
    console.log("this.checkDepth  ", this.checkDepth)

    if(this.dropTypeItems.length == 1){
      this.dropItemsV = this.dropTypeItems[0];
    } else {
      if(this.param.popupDetails.INQRY_TYPE == 'URL'){
        this.dropItemsV = this.dropTypeItems.filter(obj => obj.CD_NM == this.param.popupDetails.INQRY_TYPE)[0];
      } else {
        this.dropItemsV = this.dropTypeItems[0];
        if(this.param.childFlag == "area"){
          this.dropTypeItems = [this.param.selBoxItems[0]]  
        }      
      }
    }
    
    
    if(this.param.childFlag == 'child') {
      this.txtFieldV = this.param.popupDetails.INQRY_TYP_NM;
      this.txtUrlV  = this.param.popupDetails.INQRY_URL;
    }
    if(this.param.childFlag == 'area') this.txtAreaV  = this.param.popupDetails.msg;
  },

  computed: {

  },
};

</script>

<style></style>
